import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"

import HeaderContext from "../context/header-context"
import MotionWrapper from "../components/motion-wrapper"
import Container from "../components/container"
import Slab from "../components/slab"
import LinkList from "../components/link-list"
import SEO from "../components/seo"

const WorkPage = ({ data }) => {
  const { entries } = data.craft

  const { updateHeaderState } = useContext(HeaderContext)

  useEffect(() => {
    updateHeaderState({
      section: "Work",
      menuLinkHoverColor: "accent",
    })
  }, [updateHeaderState])

  return (
    <>
      <SEO
        description="View the featured projects portfolio of Mutual"
        title="Work"
      />
      <MotionWrapper>
        <Slab first bg="primary" color="background">
          <Container>
            <LinkList links={entries} parentPrefix="/made" />
          </Container>
        </Slab>
      </MotionWrapper>
    </>
  )
}

export const WorkPageQuery = graphql`
  {
    craft {
      entries: entries(section: "work") {
        title
        slug
      }
    }
  }
`

export default WorkPage
